import { useEffect, useMemo, useState } from "react";
import { format, parseISO } from "date-fns";
import { MdClose } from "react-icons/md";
import Swal from "sweetalert2";
import Grid from "components/grid";

const Notifications = () => {
  const [data, setData] = useState([]);
  const [post, setPost] = useState([]);

  const [loading, setLoading] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [formData, setFormData] = useState({});

  const [showModal, setShowModal] = useState(false);

  const token = useMemo(() => {
    return localStorage.getItem("auth");
  });

  useEffect(() => {
    if (token) {
      getAll();

      const endpoint = `${process.env.REACT_APP_URL_BASE}/post`;

      fetch(endpoint, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (json && !json.error) {
            setPost(json);
          }
        })
        .catch(() => {});
    }
  }, [token]);

  const getAll = () => {
    const endpoint = `${process.env.REACT_APP_URL_BASE}/notifications`;
    setLoading(true);
    fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json && !json.error) {
          setData(json);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const endpoint = `${process.env.REACT_APP_URL_BASE}/add-notification`;
    setLoading(true);
    fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((json) => {
        setLoading(false);

        if (json && json.errors) {
          const errorsString = Object.entries(json.errors)
            .flatMap(([key, value]) => value)
            .join(" ");
          return Swal.fire({
            type: "error",
            title: "Oopss. Ocurrio un error",
            text: errorsString,
          });
        }

        getAll();
        onClose();
      })
      .catch(() => {
        setLoading(false);
        onClose();
      });
  };

  const onClose = () => setShowModal(false);
  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 ">
        <div className="mt-8 flex flex-row items-center justify-between">
          <input
            type="text"
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
            className="mr-2 w-[450px] rounded-md border border-gray-300 p-2"
            placeholder="Buscar"
          />

          <button
            className="h-[40px] w-[200px] rounded-md bg-brand-500 text-white"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Crear notificación push
          </button>
        </div>
        {loading ? (
          <div className="flex h-screen items-center justify-center">
            <div className="loader h-32 w-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
          </div>
        ) : (
          <Grid
            colums={[
              { name: "Mensaje" },
              { name: "Log" },
              { name: "Apunta a" },
            ]}
            data={data}
            textFilter={textSearch}
            keyFilter={"title"}
            ComponentRow={({ item, index }) => {
              return (
                <>
                  <div class="table-cell p-2">
                    <p className="text-sm text-navy-700 dark:text-white">
                      {item.title}
                    </p>
                  </div>
                  <div class="table-cell p-2">
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Fecha registro:</span>{" "}
                      {format(parseISO(item.created_at), "yyyy-MM-dd")}
                    </p>
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Hora registro:</span>{" "}
                      {format(parseISO(item.created_at), "HH:mm")}
                    </p>
                  </div>
                  <div class="table-cell p-2">
                    <p className="text-sm text-navy-700 dark:text-white">
                      {item?.post}
                    </p>
                  </div>
                </>
              );
            }}
          />
        )}
      </div>

      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="w-[650px] rounded-lg bg-white p-8">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-xl font-semibold">
                Crear nueva notificación
              </h2>
              <button onClick={onClose} className="text-gray-500">
                <MdClose />
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="title"
                value={formData.nombre}
                onChange={handleChange}
                placeholder="Titulo"
                className="mb-4 w-full border p-2"
              />
              <input
                type="text"
                name="body"
                value={formData.email}
                onChange={handleChange}
                placeholder="Contenido"
                className="mb-4 w-full border p-2"
              />
              <div className="mb-4">
                <label
                  htmlFor="select"
                  className="mb-1 block text-sm font-medium text-gray-700"
                >
                  Novedad relacionada
                </label>
                <select
                  id="novedad"
                  name="post_id"
                  value={formData.post_id}
                  onChange={handleChange}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="">Selecciona una opción...</option>
                  {post
                    .filter((item) => item.status === "activo")
                    .map((item) => (
                      <option value={item.id}>{item.titulo}</option>
                    ))}
                </select>
              </div>

              <button
                className="rounded-lg bg-blue-500 px-4 py-2 text-white"
                type="submit"
                disabled={loading}
              >
                {loading ? "Guardando" : "Guardar"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications;
