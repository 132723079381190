import React from "react";
import { FaWpforms } from "react-icons/fa";
import Solicitudes from "views/admin/solicitudes";

import SignIn from "views/auth/SignIn";

import { MdLock } from "react-icons/md";
import { MdAppSettingsAlt } from "react-icons/md";

import SolicitudesPendientes from "views/admin/solicitudes-p";
import { FaFileWaveform } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa6";
import { BsFillPostcardHeartFill } from "react-icons/bs";
import { IoIosNotifications } from "react-icons/io";
import SolicitudesApp from "views/admin/solicitudes-app";
import Novedades from "views/admin/novedades";
import Notifications from "views/admin/notifications";
import Users from "views/admin/users";
import Dashboard from "views/admin/default";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    component: <Dashboard />,
  },

  {
    name: "Formularios web",
    layout: "/admin",
    path: "formularios",
    icon: <FaWpforms className="h-6 w-6" />,
    component: <Solicitudes />,
    secondary: true,
    associateBd: "Formulario web",
  },
  {
    name: "Sin completar",
    layout: "/admin",
    icon: <FaFileWaveform className="h-6 w-6" />,
    path: "pendientes",
    component: <SolicitudesPendientes />,
    associateBd: "Sin completar",
  },
  {
    name: "Soliciudes aplicación",
    layout: "/admin",
    icon: <MdAppSettingsAlt className="h-6 w-6" />,
    path: "aplicacion",
    component: <SolicitudesApp />,

    associateBd: "Solicitudes app",
  },
  {
    name: "Usuarios",
    layout: "/admin",
    icon: <FaUsers className="h-6 w-6" />,
    path: "users",
    component: <Users />,
    associateBd: "Usuarios",
  },
  {
    name: "Novedades",
    layout: "/admin",
    icon: <BsFillPostcardHeartFill className="h-6 w-6" />,
    path: "post",
    component: <Novedades />,
    associateBd: "Novedades",
  },
  {
    name: "Notificaciones",
    layout: "/admin",
    icon: <IoIosNotifications className="h-6 w-6" />,
    path: "notifications",
    component: <Notifications />,
    associateBd: "Notificaciones",
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
    notBar: true,
  },
];
export default routes;
