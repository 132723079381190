import InputField from "components/fields/InputField";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SignIn() {
  const [form, setForm] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");

  const navigate = useNavigate();

  const handleAuth = async ({ email, password }) => {
    const options = {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    };

    setLoading(true);
    const endpoint = `${process.env.REACT_APP_URL_BASE}/login`;
    const response = await fetch(endpoint, options)
      .then((res) => res.json())
      .catch((error) => {
        return null;
      });

    setLoading(false);
    if (!response?.errors) {
      if (response.status === "error") return;

      localStorage.setItem("nameUser", response.user.name);

      localStorage.setItem("auth", response.authorisation.token);
      localStorage.setItem(
        "permisos",
        JSON.stringify(response.user.permisos ?? [])
      );
      return navigate("/admin/default");
    }

    setError("Error de usuario");
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Iniciar sesión
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Ingresa usuario y contraseña
        </p>

        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Correo*"
          placeholder=""
          id="email"
          type="text"
          value={form.email}
          onChange={(event) => {
            setForm((old) => {
              return { ...old, email: event.target.value };
            });
          }}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Contraseña"
          placeholder=""
          id="password"
          type="password"
          onChange={(event) => {
            setForm((old) => {
              return { ...old, password: event.target.value };
            });
          }}
        />
        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2 text-red-400">
          {error}
        </div>
        <button
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={() => {
            handleAuth({
              email: form.email,
              password: form.password,
            });
          }}
          disabled={loading}
        >
          {loading ? "Verificando" : "Entrar"}
        </button>
      </div>
    </div>
  );
}
