import { useState } from "react";
import UsersApp from "./types/app";
import AdminUsers from "./types/admin-users";

const Users = () => {
  const [active, setActive] = useState(1);

  const getClassActive = () => " rounded-tl-lg  bg-brand-600 text-white";

  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 ">
        <div class="rounded-lg border border-gray-300">
          <div class="flex">
            <button
              class={`px-4 py-2  ${
                active === 1 ? getClassActive() : "bg-gray-200"
              }`}
              onClick={() => setActive(1)}
            >
              Usuarios aplicación
            </button>
            <button
              class={`px-4 py-2  ${
                active === 2 ? getClassActive() : "bg-gray-200"
              }`}
              onClick={() => setActive(2)}
            >
              Usuarios administrador / asesor
            </button>
          </div>
          <div class="p-4">
            <UsersApp active={active} />
            <AdminUsers active={active} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
