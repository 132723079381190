import { useMemo } from "react";

const Dashboard = () => {
  const nameUser = useMemo(() => {
    return localStorage.getItem("nameUser");
  }, []);
  return (
    <div>
      <div className="mt-3 flex items-center justify-center ">
        <h4 className="tex-bold text-[60px]"> Bienvenido {nameUser}</h4>
      </div>
    </div>
  );
};

export default Dashboard;
