import { useEffect, useMemo, useState } from "react";
import { format, parseISO } from "date-fns";
import Swal from "sweetalert2";
import Grid from "components/grid";
import { MdClose } from "react-icons/md";

const Novedades = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [textSearch, setTextSearch] = useState("");
  const [$formData, setFormData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const token = useMemo(() => {
    return localStorage.getItem("auth");
  });

  useEffect(() => {
    if (token) {
      getAll();
    }
  }, [token]);

  const getAll = () => {
    const endpoint = `${process.env.REACT_APP_URL_BASE}/post`;
    setLoading(true);
    fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json && !json.error) {
          setData(json);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? e.target.files[0] : value,
    }));
  };

  const onClose = () => {
    setFormData({});
    setShowModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const endpoint = `${process.env.REACT_APP_URL_BASE}/post`;
    let bodyContent = new FormData();
    bodyContent.append('titulo', $formData.titulo);
    bodyContent.append('description', $formData.description);
    bodyContent.append('imagen', $formData.imagen);

    setLoading(true);
    fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: bodyContent,
    })
      .then((res) => res.json())
      .then((json) => {
        setLoading(false);

        if (json && json.errors) {
          const errorsString = Object.entries(json.errors)
            .flatMap(([key, value]) => value)
            .join(" ");
          return Swal.fire({
            type: "error",
            title: "Oopss. Ocurrio un error",
            text: errorsString,
          });
        } else {
          getAll();
          onClose();
        }
      });
  };

  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 ">
        <div className="mt-8 flex flex-row items-center justify-between">
          <input
            type="text"
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
            className="mr-2 w-[450px] rounded-md border border-gray-300 p-2"
            placeholder="Buscar"
          />

          <button
            className="h-[40px] w-[200px] rounded-md bg-brand-500 text-white"
            onClick={() => setShowModal(true)}
          >
            Crear novedad
          </button>
        </div>
        {loading ? (
          <div className="flex h-screen items-center justify-center">
            <div className="loader h-32 w-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
          </div>
        ) : (
          <Grid
            colums={[
              { name: "Titulo" },
              { name: "Contenido" },
              { name: "Imagen" },
              { name: "Log" },
              { name: "Asignar estados" },
            ]}
            data={data}
            textFilter={textSearch}
            keyFilter={"titulo"}
            ComponentRow={({ item, index }) => {
              return (
                <>
                  <div class="table-cell p-2">
                    <p className="text-sm text-navy-700 dark:text-white">
                      {item.titulo}
                    </p>
                  </div>
                  <div class="table-cell p-2">
                    <p className="text-sm text-navy-700 dark:text-white">
                      {item.description}
                    </p>
                  </div>
                  <div class="table-cell p-2">
                    <img
                      src={item.imagen}
                      alt="imagen"
                      className="h-[50px] w-[50px] object-cover"
                    />
                  </div>
                  <div class="table-cell p-2">
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Fecha registro:</span>
                      {format(parseISO(item.created_at), "yyyy-MM-dd")}
                    </p>
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Hora registro:</span>
                      {format(parseISO(item.created_at), "HH:mm")}
                    </p>
                  </div>
                  <div class="table-cell p-2">
                    <div className="flex flex-col items-center gap-2">
                      {item.status === "activo" && (
                        <button
                          className="w-[100px] rounded-md bg-brand-500 text-white"
                          onClick={() => {
                            Swal.fire({
                              title: "¿Se desactivará esta novedad?",
                              text: "Al desactivar la novedad esta no se verá en la aplicación",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: "Si",
                              cancelButtonText: "No",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const endpoint = `${process.env.REACT_APP_URL_BASE}/post/${item.id}`;
                                setLoading(true);
                                fetch(endpoint, {
                                  method: "PUT",
                                  headers: {
                                    Authorization: "Bearer " + token,
                                    "Content-type":
                                      "application/json; charset=UTF-8",
                                  },
                                  body: JSON.stringify({
                                    status: "inactivo",
                                  }),
                                })
                                  .then((res) => res.json())
                                  .then((json) => {
                                    getAll();
                                  })
                                  .catch(() => {
                                    setLoading(false);
                                  });
                              }
                            });
                          }}
                        >
                          Desactivar
                        </button>
                      )}
                      {item.status === "inactivo" && (
                        <button
                          className="w-[100px] rounded-md bg-brand-500 text-white"
                          onClick={() => {
                            Swal.fire({
                              title: "¿Se activará esta novedad?",
                              text: "Al activará la novedad esta se verá en la aplicación",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: "Si",
                              cancelButtonText: "No",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                const endpoint = `${process.env.REACT_APP_URL_BASE}/post/${item.id}`;
                                setLoading(true);
                                fetch(endpoint, {
                                  method: "PUT",
                                  headers: {
                                    Authorization: "Bearer " + token,
                                    "Content-type":
                                      "application/json; charset=UTF-8",
                                  },
                                  body: JSON.stringify({
                                    status: "activo",
                                  }),
                                })
                                  .then((res) => res.json())
                                  .then((json) => {
                                    getAll();
                                  })
                                  .catch(() => {
                                    setLoading(false);
                                  });
                              }
                            });
                          }}
                        >
                          Activar
                        </button>
                      )}
                      <button
                        className="w-[100px] rounded-md bg-brand-500 text-white"
                        onClick={() => {
                          Swal.fire({
                            title: "¿Se eliminará esta novedad?",
                            text: "Al eliminará la novedad esta novedad se borrará definitivamente",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Si",
                            cancelButtonText: "No",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              const endpoint = `${process.env.REACT_APP_URL_BASE}/post/${item.id}`;
                              setLoading(true);
                              fetch(endpoint, {
                                method: "DELETE",
                                headers: {
                                  Authorization: "Bearer " + token,
                                },
                              })
                                .then((res) => res.json())
                                .then((json) => {
                                  getAll();
                                })
                                .catch(() => {
                                  setLoading(false);
                                });
                            }
                          });
                        }}
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                </>
              );
            }}
          />
        )}
      </div>
      {showModal && (
        <div className="z-50 fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="w-[650px] rounded-lg bg-white p-8">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-xl font-semibold">Crear novedad</h2>
              <button onClick={onClose} className="text-gray-500">
                <MdClose />
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="titulo"
                value={$formData.titulo}
                onChange={handleChange}
                placeholder="Titulo"
                className="mb-4 w-full border p-2"
              />
              <textarea
                name="description"
                value={$formData.description}
                onChange={handleChange}
                placeholder="Descripción"
                className="mb-4 w-full border p-2"
                rows={6}
              />

              <input
                type="file"
                name="imagen"
                onChange={handleChange}
                className="mb-4 w-full border p-2"
              />

              <button
                className="rounded-lg bg-blue-500 px-4 py-2 text-white"
                type="submit"
                disabled={loading}
              >
                {loading ? "Guardando" : "Guardar"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Novedades;
