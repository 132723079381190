const conveniosList = {
  1: "Gobierno del Estado de Chihuahua",
  2: "Instituto chihuahuense de salud",
  3: "Servicios de Salud de Chihuahua",
  4: "Procuraduría federal del consumidor",
  5: "Congreso del Estado de Chihuahua",
  6: "Colegio de bachilleres de Chihuahua",
  7: "Comisión estatal de vivienda de Chihuahua",
  8: "Colegio de estudios cientificos y tecnológicos del estado de Chihuahua",
  9: "Secretaría de educación de Durango SNTE 44",
  10: "Instituto de pensiones de Chihuahua",
  11: "Tribunal superior de justicia de Chihuahua",
  12: "Universidad autónoma de Chihuahua",
  13: "Poder judicial del estado de Nuevo León",
  14: "Gobierno de Aguascalientes",
  15: "Junta municipal de aguas y saneamiento de Chihuahua",
  16: "Issemym",
  17: "Instituto de pensiones de Jalisco",
};

export default conveniosList;
