import { useEffect, useMemo, useState } from "react";
import { format, parseISO } from "date-fns";
import Swal from "sweetalert2";
import Grid from "components/grid";
import Filter from "components/filter";
import { MdClose } from "react-icons/md";

const UsersApp = ({ active }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [textSearch, setTextSearch] = useState("");
  const [dataExport, setDataExport] = useState([]);
  const [formData, setFormData] = useState({});

  const [showModal, setShowModal] = useState(false);

  const token = useMemo(() => {
    return localStorage.getItem("auth");
  });

  useEffect(() => {
    if (token) {
      getAll();
    }
  }, [token]);

  const getAll = () => {
    const endpoint = `${process.env.REACT_APP_URL_BASE}/users`;
    setLoading(true);
    fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json && !json.error) {
          setData(json);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleFilter = () => {
    getAll();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Edición",
      text: "Continuar con la edición",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        const endpoint = `${process.env.REACT_APP_URL_BASE}/user-update/${formData.id}`;
        setLoading(true);
        fetch(endpoint, {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({
            email: formData.email,
            name: formData.name,
            middleName: formData.middleName,
            lastName: formData.lastName,
            phone: formData.phone,
          }),
        })
          .then((res) => res.json())
          .then((json) => {
            getAll();
            onClose()
          })
          .catch(() => {
            setLoading(false);
          });
      }
    });
  };

  const onClose = () => {
    setFormData({});
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className={`py-4 ${active === 1 ? " " : "hidden"}`}>
      <Filter
        values={{
          startDate,
          endDate,
        }}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleFilter={handleFilter}
        dataExport={dataExport}
        showFilterText={true}
        setTextSearch={setTextSearch}
        textSearch={textSearch}
        exportColumns={[
          {
            headerName: "ID",
            field: "id",
          },
          {
            headerName: "FECHA REGISTRO",
            field: "created_at",
          },
          {
            headerName: "NOMBRE",
            field: "name",
          },
          {
            headerName: "PATERNO",
            field: "middleName",
          },
          {
            headerName: "MATERNO",
            field: "lastName",
          },
          {
            headerName: "CORREO",
            field: "email",
          },
        ]}
      />

      {loading ? (
        <div className="flex h-screen items-center justify-center">
          <div className="loader h-32 w-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
        </div>
      ) : (
        <Grid
          setDataExport={setDataExport}
          colums={[
            { name: "Datos" },
            { name: "Tipo de usuario" },
            { name: "Registro" },
            { name: "Asignar estado" },
            { name: "Editar usuario" },
            { name: "Contraseña" },
          ]}
          data={data}
          textFilter={textSearch}
          keyFilter={"name"}
          ComponentRow={({ item }) => {
            return (
              <>
                <div class="table-cell p-2">
                  <p className="text-sm text-navy-700 dark:text-white">
                    <span className="font-bold">Id:</span> {item.id}
                  </p>
                  <p className="text-sm text-navy-700 dark:text-white">
                    <span className="font-bold">Nombre:</span> {item.name}
                  </p>
                  <p className="text-sm text-navy-700 dark:text-white">
                    <span className="font-bold">Correo:</span> {item.email}
                  </p>
                </div>
                <div class="table-cell p-2">Usuario app</div>
                <div class="table-cell p-2">
                  <p className="text-sm text-navy-700 dark:text-white">
                    <span className="font-bold">Fecha solicitud:</span>{" "}
                    {format(parseISO(item.created_at), "yyyy-MM-dd")}
                  </p>
                  <p className="text-sm text-navy-700 dark:text-white">
                    <span className="font-bold">Hora registro:</span>{" "}
                    {format(parseISO(item.created_at), "HH:mm")}
                  </p>
                </div>
                <div class="table-cell p-2">
                  <div className="flex flex-col items-center gap-2">
                    <button
                      className="w-[100px] rounded-md bg-brand-500 text-white"
                      onClick={() => {
                        const title =
                          item.status === "activo"
                            ? "¿Se desactivará al usuario?"
                            : "¿Se activara al usuario?";
                        const text =
                          item.status === "activo"
                            ? "Al desactivar al usuario no podrá acceder a la aplicación"
                            : "Al activar al usuario este podrá acceder a la aplicación y solicitar creditos";

                        Swal.fire({
                          title,
                          text,
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Si",
                          cancelButtonText: "No",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            const endpoint = `${process.env.REACT_APP_URL_BASE}/users-status/${item.id}`;
                            setLoading(true);
                            fetch(endpoint, {
                              method: "PUT",
                              headers: {
                                Authorization: "Bearer " + token,
                                "Content-type":
                                  "application/json; charset=UTF-8",
                              },
                              body: JSON.stringify({
                                status:
                                  item.status === "activo"
                                    ? "inactivo"
                                    : "activo",
                              }),
                            })
                              .then((res) => res.json())
                              .then((json) => {
                                getAll();
                              })
                              .catch(() => {
                                setLoading(false);
                              });
                          }
                        });
                      }}
                    >
                      {item.status === "activo" ? "Desactivar" : "Activar"}
                    </button>

                    <button
                      className="w-[100px] rounded-md bg-brand-500 text-white"
                      onClick={() => {
                        const title = "Eliminar usuario";
                        const text =
                          "El usuario quedara eliminado y no podrá acceder a la app";

                        Swal.fire({
                          title,
                          text,
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Si",
                          cancelButtonText: "No",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            const endpoint = `${process.env.REACT_APP_URL_BASE}/users-delete/${item.id}`;
                            setLoading(true);
                            fetch(endpoint, {
                              method: "delete",
                              headers: {
                                Authorization: "Bearer " + token,
                                "Content-type":
                                  "application/json; charset=UTF-8",
                              },
                            })
                              .then((res) => res.json())
                              .then((json) => {
                                getAll();
                              })
                              .catch(() => {
                                setLoading(false);
                              });
                          }
                        });
                      }}
                    >
                      Eliminar
                    </button>
                  </div>
                </div>

                <div class="table-cell p-2">
                  <button
                    className="w-[100px] rounded-md bg-brand-500 text-white"
                    onClick={() => {
                      setShowModal(true);
                      setFormData(item);
                    }}
                  >
                    Editar datos
                  </button>
                </div>
                <div class="table-cell p-2">
                  <button
                    className="w-[100px] rounded-md bg-brand-500 text-white"
                    onClick={() => {
                      const title = "Resetear contraseña";
                      const text =
                        "Al usuario se le mandara un correo con una nueva contraseña";

                      Swal.fire({
                        title,
                        text,
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Si",
                        cancelButtonText: "No",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          const endpoint = `${process.env.REACT_APP_URL_BASE}/users-reset-password/${item.id}`;
                          setLoading(true);
                          fetch(endpoint, {
                            method: "get",
                            headers: {
                              Authorization: "Bearer " + token,
                              "Content-type": "application/json; charset=UTF-8",
                            },
                          })
                            .then((res) => res.json())
                            .then((json) => {
                              getAll();
                            })
                            .catch(() => {
                              setLoading(false);
                            });
                        }
                      });
                    }}
                  >
                    Enviar email restablecer contraseña
                  </button>
                </div>
              </>
            );
          }}
        />
      )}

      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="w-[650px] rounded-lg bg-white p-8">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-xl font-semibold">Edición de usuario</h2>
              <button onClick={onClose} className="text-gray-500">
                <MdClose />
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col ">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Nombre"
                  className="mb-4 w-full border p-2"
                />
                <input
                  type="text"
                  name="middleName"
                  value={formData.middleName}
                  onChange={handleChange}
                  placeholder="Apellido paterno"
                  className="mb-4 w-full border p-2"
                />
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Apellido materno"
                  className="mb-4 w-full border p-2"
                />

                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Celular"
                  className="mb-4 w-full border p-2"
                />

                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Correo"
                  className="mb-4 w-full border p-2"
                />
              </div>

              <button
                className="rounded-lg bg-blue-500 px-4 py-2 text-white"
                type="submit"
              >
                Actualizar
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UsersApp;
