import { useEffect, useMemo, useState } from "react";
import { format, parseISO } from "date-fns";
import Swal from "sweetalert2";
import { MdClose } from "react-icons/md";
import Grid from "components/grid";

const AdminUsers = ({ active }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [formData, setFormData] = useState({});

  const [permisos, setPermisos] = useState([]);

  const token = useMemo(() => {
    return localStorage.getItem("auth");
  });

  useEffect(() => {
    if (token) {
      getAll();
    }
  }, [token]);

  const getAll = () => {
    const endpoint = `${process.env.REACT_APP_URL_BASE}/users?type=admin`;
    setLoading(true);
    fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json && !json.error) {
          setData(json);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name) {
      return Swal.fire("Ingresa el nombre");
    }

    if (!formData.middleName) {
      return Swal.fire("Ingresa los apellidos");
    }

    if (!formData.email) {
      return Swal.fire("Ingresa un correo");
    }

    if (!formData.password) {
      return Swal.fire("Ingresa la contraseña");
    }

    if (permisos.length === 0) {
      return Swal.fire("Asigna permisos al usuario");
    }

    if (!formData.type) {
      return Swal.fire("Ingresa el tipo de usuario");
    }
    const endpoint = `${process.env.REACT_APP_URL_BASE}/user-admin`;
    setLoading(true);
    fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({ ...formData, permisos }),
    })
      .then((res) => res.json())
      .then((json) => {
        setLoading(false);

        if (json && json.errors) {
          const errorsString = Object.entries(json.errors)
            .flatMap(([key, value]) => value)
            .join(" ");
          return Swal.fire({
            type: "error",
            title: "Oopss. Ocurrio un error",
            text: errorsString,
          });
        }
        getAll();
        onClose();
      });
  };

  const onClose = () => {
    setFormData({});
    setPermisos([]);
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className={`py-4 ${active === 2 ? " " : "hidden"}`}>
      <div className="mt-8 flex flex-row items-center justify-between">
        <div className="flex flex-row items-center ">
          <input
            type="text"
            value={textSearch}
            onChange={(e) => setTextSearch(e.target.value)}
            className="mr-2 w-[450px] rounded-md border border-gray-300 p-2"
            placeholder="Buscar"
          />
        </div>

        <button
          onClick={() => setShowModal(true)}
          className=" w-[130px] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          Crear usuario
        </button>
      </div>

      {loading ? (
        <div className="flex h-screen items-center justify-center">
          <div className="loader h-32 w-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
        </div>
      ) : (
        <Grid
          colums={[
            { name: "Datos" },
            { name: "Tipo de usuario" },
            { name: "Registro" },
            { name: "Permisos" },

            { name: "Asignar estado" },
          ]}
          data={data}
          textFilter={textSearch}
          keyFilter={"name"}
          ComponentRow={({ item, index }) => {
            return (
              <>
                <div class="table-cell p-2">
                  <p className="text-sm text-navy-700 dark:text-white">
                    <span className="font-bold">Id:</span> {item.id}
                  </p>
                  <p className="text-sm text-navy-700 dark:text-white">
                    <span className="font-bold">Nombre:</span> {item.name}
                  </p>
                  <p className="text-sm text-navy-700 dark:text-white">
                    <span className="font-bold">Correo:</span> {item.email}
                  </p>
                </div>
                <div class="table-cell p-2">
                  {item.type === "admin" ? "administrador" : "asesor"}
                </div>
                <div class="table-cell p-2">
                  <p className="text-sm text-navy-700 dark:text-white">
                    <span className="font-bold">Fecha solicitud:</span>{" "}
                    {format(parseISO(item.created_at), "yyyy-MM-dd")}
                  </p>
                  <p className="text-sm text-navy-700 dark:text-white">
                    <span className="font-bold">Hora registro:</span>{" "}
                    {format(parseISO(item.created_at), "HH:mm")}
                  </p>
                </div>
                <div class="table-cell p-2">
                  <div className="flex flex-col">
                    <ol>
                      {item.permisos &&
                        item.permisos.map((item) => {
                          return <li>{item}</li>;
                        })}
                    </ol>
                  </div>
                </div>
                <div class="table-cell p-2">
                  <div className="flex flex-col items-center gap-2">
                    <button
                      className="w-[100px] rounded-md bg-brand-500 text-white"
                      onClick={() => {
                        const title =
                          item.status === "activo"
                            ? "¿Se desactivará al usuario?"
                            : "¿Se activara al usuario?";
                        const text =
                          item.status === "activo"
                            ? "Al desactivar al usuario no podrá acceder a la aplicación"
                            : "Al activar al usuario este podrá acceder a la aplicación y solicitar creditos";

                        Swal.fire({
                          title,
                          text,
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Si",
                          cancelButtonText: "No",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            const endpoint = `${process.env.REACT_APP_URL_BASE}/users-status/${item.id}`;
                            setLoading(true);
                            fetch(endpoint, {
                              method: "PUT",
                              headers: {
                                Authorization: "Bearer " + token,
                                "Content-type":
                                  "application/json; charset=UTF-8",
                              },
                              body: JSON.stringify({
                                status:
                                  item.status === "activo"
                                    ? "inactivo"
                                    : "activo",
                              }),
                            })
                              .then((res) => res.json())
                              .then((json) => {
                                getAll();
                              })
                              .catch(() => {
                                setLoading(false);
                              });
                          }
                        });
                      }}
                    >
                      {item.status === "activo" ? "Desactivar" : "Activar"}
                    </button>

                    <button
                      className="w-[100px] rounded-md bg-brand-500 text-white"
                      onClick={() => {
                        const title = "Eliminar usuario";
                        const text =
                          "El usuario quedara eliminado y no podrá acceder a la app";

                        Swal.fire({
                          title,
                          text,
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Si",
                          cancelButtonText: "No",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            const endpoint = `${process.env.REACT_APP_URL_BASE}/users-delete/${item.id}`;
                            setLoading(true);
                            fetch(endpoint, {
                              method: "delete",
                              headers: {
                                Authorization: "Bearer " + token,
                                "Content-type":
                                  "application/json; charset=UTF-8",
                              },
                            })
                              .then((res) => res.json())
                              .then((json) => {
                                getAll();
                              })
                              .catch(() => {
                                setLoading(false);
                              });
                          }
                        });
                      }}
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              </>
            );
          }}
        />
      )}

      {showModal && (
        <div className="z-50 fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="w-[650px] rounded-lg bg-white p-8">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-xl font-semibold">Crear usuario admin</h2>
              <button onClick={onClose} className="text-gray-500">
                <MdClose />
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-2">
                <div className="flex flex-col ">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Nombre"
                    className="mb-4 w-full border p-2"
                  />
                  <input
                    type="text"
                    name="middleName"
                    value={formData.middleName}
                    onChange={handleChange}
                    placeholder="Apellidos"
                    className="mb-4 w-full border p-2"
                  />

                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Correo"
                    className="mb-4 w-full border p-2"
                  />

                  <input
                    type="text"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Contraseña"
                    className="mb-4 w-full border p-2"
                  />

                  <div className="mb-4">
                    <label
                      htmlFor="select"
                      className="mb-1 block text-sm font-medium text-gray-700"
                    >
                      Tipo de usuario
                    </label>
                    <select
                      id="type"
                      name="type"
                      value={formData.type}
                      onChange={handleChange}
                      className="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="">Selecciona una opción...</option>
                      {[
                        {
                          value: "admin",
                          text: "Administrador",
                        },
                        {
                          value: "asesor",
                          text: "Asesor",
                        },
                      ].map((item) => (
                        <option value={item.value}>{item.text}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div class="relative mb-4 overflow-x-auto shadow-md sm:rounded-lg">
                  <table class="w-full text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right">
                    <thead class="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th scope="col" class="px-6 py-3">
                          Permisos
                        </th>

                        <th scope="col" class="px-6 py-3">
                          <span class="sr-only">Asignar</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {[
                        "Formulario web",
                        "Sin completar",
                        "Solicitudes app",
                        "Usuarios",
                        "Novedades",
                        "Notificaciones",
                      ].map((item, index) => {
                        return (
                          <tr class="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
                            <th
                              scope="row"
                              class="font-small whitespace-nowrap px-6 py-4 text-gray-900 dark:text-white"
                            >
                              {item}
                            </th>

                            <td class="px-6 py-4 text-right">
                              <input
                                type="checkbox"
                                name={item}
                                id={index}
                                checked={permisos.includes(item)}
                                onChange={() => {
                                  if (permisos.includes(item)) {
                                    setPermisos((old) => {
                                      let _old = [...old];
                                      const indexItem = _old.findIndex(
                                        (_item) => _item === item
                                      );

                                      _old.splice(indexItem, 1);

                                      return _old;
                                    });
                                  } else {
                                    setPermisos((old) => {
                                      return [...old, item];
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <button
                className="rounded-lg bg-blue-500 px-4 py-2 text-white"
                type="submit"
              >
                Guardar
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminUsers;
