import { useEffect, useMemo, useState } from "react";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
const Grid = ({
  data,
  ComponentRow,
  colums,
  textFilter,
  keyFilter,
  setDataExport,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);

  const dataGrid = useMemo(() => {
    if (!data) return [];
    return data.filter((item) => {
      if (keyFilter) {
        const matchName = item[keyFilter]
          .toLowerCase()
          .includes(textFilter.toLowerCase());

        return matchName;
      }
      return item;
    });
  }, [data, textFilter]);

  useEffect(() => {
    console.log(setDataExport, dataGrid);
    if (setDataExport) {
      setDataExport(dataGrid);
    }
  }, [dataGrid]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dataGrid.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const totalPages = Math.ceil(dataGrid.length / itemsPerPage);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div class="container mx-auto mt-10">
      <div class="table w-full">
        <div class="table-row-group">
          <div class="table-row bg-gray-200">
            {colums.map((item, index) => {
              return (
                <div class="table-cell p-2 font-bold" key={`col_${index}`}>
                  {item.name}
                </div>
              );
            })}
          </div>
          {currentItems &&
            currentItems.map((item, index) => {
              return (
                <div
                  className={
                    index % 2 === 0
                      ? "table-row bg-white"
                      : "table-row bg-gray-100"
                  }
                >
                  {ComponentRow && <ComponentRow item={item} index={index} />}
                </div>
              );
            })}
        </div>
      </div>
      <div className="mt-4 flex justify-start">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="mr-2 rounded-l bg-gray-300 px-4 py-2 font-bold text-gray-800 hover:bg-gray-400"
        >
          <GrFormPrevious />
        </button>
        {Array.from(Array(totalPages).keys()).map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => handlePageChange(pageNumber + 1)}
            className={`mr-2 rounded-full bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 ${
              pageNumber + 1 === currentPage ? "bg-blue-700" : ""
            }`}
          >
            {pageNumber + 1}
          </button>
        ))}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={indexOfLastItem >= dataGrid.length}
          className="ml-2 rounded-r bg-gray-300 px-4 py-2 font-bold text-gray-800 hover:bg-gray-400"
        >
          <MdNavigateNext />
        </button>
      </div>
    </div>
  );
};

export default Grid;
