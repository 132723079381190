import { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import Grid from "components/grid";
import Filter from "components/filter";
import { endOfMonth, format, startOfMonth } from "date-fns";

const Solicitudes = () => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(
    format(startOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(
    format(endOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [loading, setLoading] = useState(false);
  const [dataExport, setDataExport] = useState([]);

  const handleFilter = () => {
    getAll();
  };

  useEffect(() => {
    getAll();
  }, []);

  const getAll = () => {
    const endpoint = `${process.env.REACT_APP_URL_BASE}/all-solicitudes?start=${startDate}&end=${endDate}`;
    setLoading(true);
    fetch(endpoint)
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          setData(json);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const renderUtm = (utm) => {
    if (!utm) return;
    if (utm === "[]") return;
    if (utm.indexOf("echo") > -1) return;

    try {
      const dataUtm = JSON.parse(utm);
      if (dataUtm) {
        return Object.entries(JSON.parse(utm)).map(([key, value]) => {
          if (["gclid", "ctf_src", "ctf_mt", "ctf_ver"].includes(key))
            return "";
          return <span>{`${key}: ${value}`}</span>;
        });
      }
    } catch (error) {}
  };

  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 ">
        <Filter
          values={{
            startDate,
            endDate,
          }}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleFilter={handleFilter}
          dataExport={dataExport}
          exportColumns={[
            {
              headerName: "Nombre",
              field: "firtsName",
            },
            {
              headerName: "Apellidos",
              field: "lastName",
            },
            {
              headerName: "Correo",
              field: "email",
            },
            {
              headerName: "Telefono",
              field: "phone",
            },
            { headerName: "Convenio", field: "convenio" },
            {
              headerName: "Monto solicitudes",
              field: "amountRequest",
            },
            {
              headerName: "Pagar en quincenas",
              field: "payIn",
            },
            {
              headerName: "Pagos",
              field: "payment",
            },
            {
              headerName: "UTM",
              field: "utm",
            },
          ]}
        />

        {loading ? (
          <div className="flex h-screen items-center justify-center">
            <div className="loader h-32 w-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
          </div>
        ) : (
          <Grid
            colums={[
              { name: "Contacto" },
              { name: "Convenio" },
              { name: "Solicitud" },
              { name: "UTM" },
              { name: "Acción" },
            ]}
            setDataExport={setDataExport}
            data={data}
            ComponentRow={({ item }) => {
              return (
                <>
                  <div class="table-cell p-2">
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Nombre:</span>{" "}
                      {item.firtsName} {item.lastName}
                    </p>
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Teléfono:</span> {item.phone}
                    </p>
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Email:</span> {item.email}
                    </p>
                  </div>
                  <div class="table-cell p-2">
                    <p className="text-sm text-navy-700 dark:text-white">
                      {item.convenio}
                    </p>
                  </div>
                  <div class="table-cell p-2">
                    {item.amountRequest ? (
                      <>
                        <p className="text-sm text-navy-700 dark:text-white">
                          <span className="font-bold">
                            Cant. total de crédito
                          </span>{" "}
                          ${" "}
                          {item.amountRequest.toLocaleString("es-MX", {
                            style: "currency",
                            currency: "MXN",
                          })}
                        </p>
                        <p className="text-sm text-navy-700 dark:text-white">
                          <span className="font-bold"> No de quincenas</span>{" "}
                          {item.payIn}
                        </p>
                        <p className="text-sm text-navy-700 dark:text-white">
                          <span className="font-bold">Pago quincenal</span> $
                          {item.payment.toLocaleString("es-MX", {
                            style: "currency",
                            currency: "MXN",
                          })}
                        </p>
                      </>
                    ) : null}
                  </div>
                  <div class="table-cell p-2">
                    <div className="grid grid-cols-2 gap-1">
                      {renderUtm(item?.utm)}
                    </div>
                  </div>
                  <div class="table-cell p-2">
                    <FaWhatsapp
                      onClick={() => {
                        window.open(`https://wa.me/${item.phone}`);
                      }}
                      size={28}
                    />
                  </div>
                </>
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Solicitudes;
