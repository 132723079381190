import { format } from "date-fns";
import CreateExcel from "excel/excel";
import Swal from "sweetalert2";

const Filter = ({
  values,
  dataExport,
  setStartDate,
  setEndDate,
  handleFilter,
  textSearch,
  setTextSearch,
  showFilterText,
  exportColumns,
}) => {
  return (
    <div className="mt-8 flex flex-row items-center">
      <input
        type="date"
        value={values?.startDate}
        onChange={(e) => setStartDate(e.target.value)}
        className="mr-2 rounded-md border border-gray-300 p-2"
      />
      <input
        type="date"
        value={values?.endDate}
        onChange={(e) => setEndDate(e.target.value)}
        className="mr-2 rounded-md border border-gray-300 p-2"
      />
      <button
        onClick={handleFilter}
        className="mr-5 w-[100px] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
      >
        Filtrar
      </button>

      <button
        onClick={() => {

          console.log(dataExport)
          if (!dataExport || dataExport.length === 0) {
            return Swal.fire({
              type: "waring",
              title: "Advertencia",
              text: "No hay información para exportar",
            });
          }

          if (!exportColumns || exportColumns.length === 0) {
            return Swal.fire({
              type: "waring",
              title: "Advertencia",
              text: "No hay columnas para exportar. Contarta a soporte",
            });
          }

          const url = window.location.href;
          const partes = url.split("/");
          const ultimaParte = partes[partes.length - 1];
          const excel = new CreateExcel(
            `todo_paga_${ultimaParte}_${format(new Date(), "yyyy_MM_dd")}`,
            exportColumns,
            dataExport
          );
          excel.createDefaultExcel();
        }}
        className=" w-[200px] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
      >
        Descargar excel
      </button>

      {showFilterText && (
        <input
          type="text"
          value={textSearch}
          onChange={(e) => setTextSearch(e.target.value)}
          className="ml-3 mr-2 w-[450px] rounded-md border border-gray-300 p-2"
          placeholder="Buscar"
        />
      )}
    </div>
  );
};

export default Filter;
