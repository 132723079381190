import { useEffect, useMemo, useState } from "react";
import { endOfMonth, format, parseISO, startOfMonth } from "date-fns";
import conveniosList from "variables/convenios";
import Filter from "components/filter";
import Grid from "components/grid";
import { MdClose } from "react-icons/md";
import Swal from "sweetalert2";

const SolicitudesApp = () => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(
    format(startOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(
    format(endOfMonth(new Date()), "yyyy-MM-dd")
  );
  const [loading, setLoading] = useState(false);

  const [textSearch, setTextSearch] = useState("");

  const [formData, setFormData] = useState({});

  const [showModal, setShowModal] = useState({
    visible: false,
    data: {},
  });
  const [isOpen, setIsOpen] = useState({});
  const [active, setActive] = useState(1);

  const [dataExport, setDataExport] = useState([]);

  const getClassActive = () => " rounded-tl-lg  bg-brand-600 text-white";

  const handleFilter = () => {
    getAll();
  };

  const token = useMemo(() => {
    return localStorage.getItem("auth");
  });

  useEffect(() => {
    getAll();
  }, []);

  const getAll = () => {
    const endpoint = `${process.env.REACT_APP_URL_BASE}/solicitudes-app?start=${startDate}&end=${endDate}`;
    setLoading(true);
    fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json && !json.error) {
          setData(json);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onClose = () => setShowModal({ visible: false, data: null });

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.convenio) {
      return Swal.fire("Ingresa el convenio");
    }

    if (!formData.plazo) {
      return Swal.fire("Selecciona el plazo");
    }

    if (!formData.monto_quincenal) {
      return Swal.fire("Ingresa el monto quincenal");
    }

    if (!formData.monto) {
      return Swal.fire("Ingresa el monto a asignar");
    }

    if (!formData.tipo_plazo) {
      return Swal.fire("Selecciona el tipo de plazo");
    }

    const endpoint = `${process.env.REACT_APP_URL_BASE}/update-request/${showModal.data.id}`;
    setLoading(true);
    fetch(endpoint, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({ ...formData }),
    })
      .then((res) => res.json())
      .then((json) => {
        setLoading(false);

        if (json && json.errors) {
          const errorsString = Object.entries(json.errors)
            .flatMap(([key, value]) => value)
            .join(" ");
          return Swal.fire({
            type: "error",
            title: "Oopss. Ocurrio un error",
            text: errorsString,
          });
        }
        getAll();
        onClose();
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateStatus = ({ id, status }) => {
    const optionsRechazo = {
      1: "El Cliente no es empleado de ningún convenio de gobierno",
      2: "Los documentos cargados son ilegibles",
      3: "Cliente presenta riesgo de jubilación",
      4: "Sin capacidad de pago",
      5: "Convenio suspendido",
      6: "Mal manejo de créditos anteriores",
      7: "Falta antigüedad en el empleo",
      8: "No cumple con políticas para otorgamiento de crédito",
    };

    if (status === "rechazado") {
      Swal.fire({
        title: "Se rechazara la solicitud",
        input: "select",
        inputOptions: optionsRechazo,
        inputPlaceholder: "Selecciona una opción de rechazo",
        showCancelButton: true,
        inputValidator: function (value) {
          return new Promise(function (resolve, reject) {
            if (value !== "") {
              resolve();
            } else {
              resolve("You need to select a Tier");
            }
          });
        },
      }).then(function (result) {
        if (result.isConfirmed) {
          callApi({
            status,
            id,
            razon: optionsRechazo[result.value],
          });
        }
      });
    } else {
      callApi({
        status,
        id,
        razon: "",
      });
    }
  };

  const callApi = ({ status, id, razon }) => {
    const endpoint = `${process.env.REACT_APP_URL_BASE}/update-status/${id}`;
    setLoading(true);
    fetch(endpoint, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({ status, razon }),
    })
      .then((res) => res.json())
      .then((json) => {
        setLoading(false);

        if (json && json.errors) {
          const errorsString = Object.entries(json.errors)
            .flatMap(([key, value]) => value)
            .join(" ");
          return Swal.fire({
            type: "error",
            title: "Oopss. Ocurrio un error",
            text: errorsString,
          });
        }
        getAll();
        onClose();
      });
  };

  const closeModal = () => {
    setIsOpen({
      open: false,
    });
    setActive(1);
  };

  const obtenerExtension = (url) => {
    if (!url) return "";
    const ultimaParte = url.split(".").pop();
    return ultimaParte;
  };

  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 ">
        <Filter
          values={{
            startDate,
            endDate,
          }}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleFilter={handleFilter}
          dataExport={dataExport}
          showFilterText={true}
          setTextSearch={setTextSearch}
          textSearch={textSearch}
          exportColumns={[
            {
              headerName: "ID",
              field: "id",
            },
            {
              headerName: "FECHA REGISTRO",
              field: "created_at",
            },
            {
              headerName: "NOMBRE",
              field: "creator_name",
            },
            {
              headerName: "CONVENIO",
              field: "convenio",
            },
            {
              headerName: "ASIGNADO",
              field: "assignee_name",
            },
            {
              headerName: "ASESOR",
              field: "creator_asesor",
            },
            {
              headerName: "Teléfono",
              field: "creator_phone",
            },
            {
              headerName: "Email",
              field: "creator_email",
            },
            {
              headerName: "Referido por",
              field: "referido_name",
            },
            {
              headerName: "Total de crédito",
              field: "monto",
            },
            {
              headerName: "Pago quincenal",
              field: "monto_quincenal",
            },
            {
              headerName: "Estatus",
              field: "status",
            },
          ]}
        />

        {loading ? (
          <div className="flex h-screen items-center justify-center">
            <div className="loader h-32 w-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear"></div>
          </div>
        ) : (
          <Grid
            setDataExport={setDataExport}
            colums={[
              { name: "Registro de solicitud" },
              { name: "Contacto" },
              { name: "Convenio" },
              { name: "Solicitud de crédito" },
              { name: " Estado de la solicitud" },
              { name: "Contacto" },
              { name: "Documentación" },
              { name: "Asignación" },
              { name: "Asesor" },
              { name: "Log" },
            ]}
            data={data}
            textFilter={textSearch}
            keyFilter={"creator_name"}
            ComponentRow={({ item }) => {
              return (
                <>
                  <div class="table-cell p-2">
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Id:</span> {item.id}
                    </p>
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Fecha solicitud:</span> <br />
                      {format(parseISO(item.created_at), "yyyy-MM-dd")}
                    </p>
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Hora registro:</span>
                      <br />
                      {format(parseISO(item.created_at), "HH:mm")}
                    </p>
                  </div>
                  <div class="table-cell p-2">
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Nombre:</span>
                      <br />
                      {item.creator_name}
                    </p>
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Teléfono:</span>
                      <br />
                      {item.creator_phone}
                    </p>
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Email:</span>
                      <br />
                      {item.creator_email}
                    </p>

                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Referido por:</span>
                      <br />
                      {item.referido_name}
                    </p>
                  </div>
                  <div class="table-cell p-2">
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Convenio:</span>
                      <br />
                      {conveniosList[item.convenio] ?? ""}
                    </p>
                  </div>
                  <div class="table-cell p-2">
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Cant. total de crédito:</span>{" "}
                      {parseFloat(item.monto ?? 0).toLocaleString("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      })}
                    </p>
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">
                        No de {item.tipo_plazo ?? "quincenal"}:
                      </span>{" "}
                      {item?.plazo ?? 0}
                    </p>
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Pago quincenal:</span>{" "}
                      {parseFloat(item.monto_quincenal ?? 0).toLocaleString(
                        "es-MX",
                        {
                          style: "currency",
                          currency: "MXN",
                        }
                      )}
                    </p>
                  </div>
                  <div class="table-cell p-2">
                    <div className="flex flex-col items-center gap-2">
                      <span>{item.status}</span>

                      {item.status !== "aprobada" && (
                        <>
                          <button
                            className="w-[100px] rounded-md bg-brand-500 text-white"
                            onClick={() => {
                              setFormData({ convenio: item.convenio });
                              setShowModal({
                                visible: true,
                                data: item,
                              });
                            }}
                          >
                            Responder solicitud
                          </button>
                          <button
                            className="w-[100px] rounded-md bg-brand-500 text-white"
                            onClick={() => {
                              updateStatus({
                                id: item.id,

                                status: "revisado",
                              });
                            }}
                          >
                            Revisada
                          </button>

                          {item.status !== "rechazado" && (
                            <button
                              className="w-[100px] rounded-md bg-brand-500 text-white"
                              onClick={() => {
                                updateStatus({
                                  id: item.id,

                                  status: "rechazado",
                                });
                              }}
                            >
                              Rechazada
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div class="table-cell p-2">
                    <button
                      onClick={() => {
                        window.open(`https://wa.me/${item.phone}`);
                      }}
                      className="mr-5 w-[100px] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    >
                      Enviar whatsapp
                    </button>
                  </div>
                  <div class="table-cell p-2">
                    <button
                      onClick={() => {
                        setIsOpen({
                          open: true,
                          ine: {
                            type: obtenerExtension(item.ine),
                            src: item.ine,
                          },
                          ine_reverso: {
                            type: obtenerExtension(item.ine_reverso),
                            src: item.ine_reverso,
                          },
                          talon: {
                            type: obtenerExtension(item.talon),
                            src: item.talon,
                          },
                          title: `${item.id} - ${item.creator_name}`,
                        });
                      }}
                      className="mr-5 w-[100px] rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    >
                      Ver documentos
                    </button>
                  </div>
                  <div class="table-cell p-2">{item?.assignee_name}</div>

                  <div class="table-cell p-2">{item?.creator_asesor}</div>
                  <div class="table-cell p-2">
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Ultima edición:</span>
                      <br />
                      {format(parseISO(item.updated_at), "yyyy-MM-dd")}
                    </p>
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Hora edición:</span>
                      <br />
                      {format(parseISO(item.updated_at), "HH:mm")}
                    </p>
                    <p className="text-sm text-navy-700 dark:text-white">
                      <span className="font-bold">Modificado por:</span>
                      <br />
                      {item?.operation_name}
                    </p>
                  </div>
                </>
              );
            }}
          />
        )}
      </div>

      {showModal?.visible && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="w-[650px] rounded-lg bg-white p-8">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-xl font-semibold">
                Responder solicitud solicitud
              </h2>
              <button onClick={onClose} className="text-gray-500">
                <MdClose />
              </button>
            </div>

            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                value={showModal.data.creator_name}
                className="mb-4 w-full border p-2"
                disabled={true}
              />
              <div className="mb-4">
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  Monto
                </label>

                <input
                  type="number"
                  name="monto"
                  className="mb-4 w-full border p-2"
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="select"
                  className="mb-1 block text-sm font-medium text-gray-700"
                >
                  Monto por quincena
                </label>

                <input
                  type="number"
                  name="monto_quincenal"
                  className="mb-4 w-full border p-2"
                  onChange={handleChange}
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="select"
                  className="mb-1 block text-sm font-medium text-gray-700"
                >
                  Tipo de plazo
                </label>
                <select
                  id="tipo_plazo"
                  name="tipo_plazo"
                  value={formData.tipo_plazo}
                  onChange={handleChange}
                  className="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="">Selecciona una opción...</option>
                  {[
                    {
                      value: "quincenal",
                      text: "Quincenal",
                    },
                    {
                      value: "mensual",
                      text: "Mensual",
                    },
                  ].map((item) => (
                    <option value={item.value}>{item.text}</option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="select"
                  className="mb-1 block text-sm font-medium text-gray-700"
                >
                  Plazos
                </label>
                <select
                  id="plazo"
                  name="plazo"
                  value={formData.plazo}
                  onChange={handleChange}
                  className="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="">Selecciona una opción...</option>
                  {[
                    {
                      value: 1,
                      text: "1",
                    },
                    {
                      value: 2,
                      text: "2",
                    },
                    {
                      value: 3,
                      text: "3",
                    },
                    {
                      value: 4,
                      text: "4",
                    },
                  ].map((item) => (
                    <option value={item.value}>{item.text}</option>
                  ))}
                </select>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="select"
                  className="mb-1 block text-sm font-medium text-gray-700"
                >
                  Convenio
                </label>
                <select
                  id="convenio"
                  name="convenio"
                  value={formData.convenio}
                  onChange={handleChange}
                  className="block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="">Selecciona una opción...</option>
                  {Object.entries(conveniosList).map(([key, value]) => (
                    <option value={key}>{value}</option>
                  ))}
                </select>
              </div>
              <button
                className="rounded-lg bg-blue-500 px-4 py-2 text-white"
                type="submit"
              >
                Guardar y aprobar
              </button>
            </form>
          </div>
        </div>
      )}

      {isOpen?.open && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="w-[650px] rounded-lg bg-white p-8">
            <div className="mb-4 flex items-center justify-between">
              <h2 className="text-xl font-semibold">
                Documentación de solicitud {isOpen.title}
              </h2>
              <button onClick={closeModal} className="text-gray-500">
                <MdClose />
              </button>
            </div>
            <div class="rounded-lg border border-gray-300">
              <div class="flex">
                <button
                  class={`px-4 py-2  ${
                    active === 1 ? getClassActive() : "bg-gray-200"
                  }`}
                  onClick={() => setActive(1)}
                >
                  Ine
                </button>
                <button
                  class={`px-4 py-2  ${
                    active === 2 ? getClassActive() : "bg-gray-200"
                  }`}
                  onClick={() => setActive(2)}
                >
                  Ine reverso
                </button>
                <button
                  class={`px-4 py-2  ${
                    active === 3 ? getClassActive() : "bg-gray-200"
                  }`}
                  onClick={() => setActive(3)}
                >
                  Talón
                </button>
              </div>
              <div class="p-4">
                <div className={`py-4 ${active === 1 ? " " : "hidden"}`}>
                  {isOpen.ine.type === "pdf" ? (
                    <iframe
                      title="PDF Viewer"
                      src={isOpen.ine.src}
                      className="h-[550px] w-full"
                    />
                  ) : (
                    <img
                      src={isOpen.ine.src}
                      alt="Imagen"
                      className="h-[550px] w-full object-contain"
                    />
                  )}
                </div>
                <div className={`py-4 ${active === 2 ? " " : "hidden"}`}>
                  {isOpen.talon.type === "pdf" ? (
                    <iframe
                      title="PDF Viewer"
                      src={isOpen.ine_reverso.src}
                      className="h-[550px] w-full"
                    />
                  ) : (
                    <img
                      src={isOpen.ine_reverso.src}
                      alt="Imagen"
                      className="h-[550px] w-full object-contain"
                    />
                  )}
                </div>
                <div className={`py-4 ${active === 3 ? " " : "hidden"}`}>
                  {isOpen.talon.type === "pdf" ? (
                    <iframe
                      title="PDF Viewer"
                      src={isOpen.talon.src}
                      className="h-[550px] w-full"
                    />
                  ) : (
                    <img
                      src={isOpen.talon.src}
                      alt="Imagen"
                      className="h-[550px] w-full object-contain"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SolicitudesApp;
